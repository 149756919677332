import { useEffect } from "react";
import { useAuth } from "../../../context/auth-context";
import { FullScreenSpinner } from "../../../tabin/components/fullScreenSpinner";
import { toast } from "../../../tabin/components/toast";

export const Logout = () => {
    const { logout } = useAuth();

    useEffect(() => {
        (async function token() {
            try {
                await logout();
            } catch (err) {
                toast.error(err);
            }
        })();
    }, []);

    return <FullScreenSpinner show={true} text="Logging Out" />;
};
