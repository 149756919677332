import { useState, createContext, useContext } from "react";

import { IGET_RESTAURANT } from "../graphql/customQueries";
import { getCloudFrontDomainName } from "../private/aws-custom";

type ContextProps = {
    restaurant: IGET_RESTAURANT | null;
    setRestaurant: (restaurant: IGET_RESTAURANT) => void;
    restaurantProductImages: any;
};

const RestaurantContext = createContext<ContextProps>({
    restaurant: null,
    setRestaurant: () => {},
    restaurantProductImages: {},
});

const RestaurantProvider = (props: { children: React.ReactNode }) => {
    const [restaurant, setRestaurant] = useState<IGET_RESTAURANT | null>(null);
    const restaurantProductImages = {};

    const _setRestaurant = (newRestaurant: IGET_RESTAURANT | null) => {
        setRestaurant(newRestaurant);
    };

    return (
        <RestaurantContext.Provider
            value={{
                restaurant: restaurant,
                setRestaurant: _setRestaurant,
                restaurantProductImages: restaurantProductImages,
            }}
            children={
                <>
                    {props.children}
                    {restaurant && restaurant.customStyleSheet && (
                        <link
                            rel="stylesheet"
                            type="text/css"
                            href={`${getCloudFrontDomainName()}/protected/${restaurant.customStyleSheet.identityPoolId}/${
                                restaurant.customStyleSheet.key
                            }`}
                        />
                    )}
                </>
            }
        />
    );
};

const useRestaurant = () => {
    const context = useContext(RestaurantContext);
    if (context === undefined) {
        throw new Error(`useRestaurant must be used within a RestaurantProvider`);
    }
    return context;
};

export { RestaurantProvider, useRestaurant };
