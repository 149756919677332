export const CloseIcon = (props: { height: string; className?: string }) => {
    return (
        <svg
            viewBox="0 0 24 24"
            role="img"
            aria-label="Close"
            focusable="false"
            style={{
                height: props.height,
                width: props.height,
                display: "block",
                fill: "rgb(118, 118, 118)",
            }}
            className={props.className}
        >
            <path
                d="m23.25 24c-.19 0-.38-.07-.53-.22l-10.72-10.72-10.72 10.72c-.29.29-.77.29-1.06 0s-.29-.77 0-1.06l10.72-10.72-10.72-10.72c-.29-.29-.29-.77 0-1.06s.77-.29 1.06 0l10.72 10.72 10.72-10.72c.29-.29.77-.29 1.06 0s .29.77 0 1.06l-10.72 10.72 10.72 10.72c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22"
                fillRule="evenodd"
            />
        </svg>
    );
};
