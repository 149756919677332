import { useEffect, useState } from "react";
import { AuthenticationStatus, useAuth } from "../../../context/auth-context";
import { useUser } from "../../../context/user-context";
import { Button } from "../../../tabin/components/button";
import { Link } from "../../../tabin/components/link";
import { Spinner } from "../../../tabin/components/spinner";
import { toast } from "../../../tabin/components/toast";
import { ForgotPassword } from "./forgotPassword";
import { ForgotPasswordReset } from "./forgotPasswordReset";
import { GuestInformation } from "./guestInformation";
import { Login } from "./login";
import { Signup } from "./signup";
import { SignupConfirm } from "./signupConfirm";

// export enum AuthenticationStatus {
//     Loading,
//     SignedIn,
//     UnconfirmedSignIn,
//     NewInformationRequired,
//     NeedConfirmation,
//     Unauthenticated,
// }

// enum AuthComponentState {
//     Login,
//     Signup,
//     SignupConfirm,
//     ForgotPassword,
//     ForgotPasswordReset,
// }

export const Auth = () => {
    // const { status, logout } = useAuth();
    // const { user } = useUser();
    // const [authComponentState, setAuthComponentState] = useState(AuthComponentState.Login);

    // console.log("xxx...status", status);

    // useEffect(() => {
    //     if (status == AuthenticationStatus.Unauthenticated) {
    //         setAuthComponentState(AuthComponentState.Login);
    //     }

    //     if (status == AuthenticationStatus.UnconfirmedSignIn) {
    //         setAuthComponentState(AuthComponentState.SignupConfirm);
    //     }

    //     if (status == AuthenticationStatus.NeedConfirmation) {
    //         setAuthComponentState(AuthComponentState.SignupConfirm);
    //     }

    //     // if (status == AuthenticationStatus.NewInformationRequired) {
    //     //     setAuthComponentState(AuthComponentState.NewInformationRequired);
    //     // }
    // }, [status]);

    // const onLogin = async () => {
    //     setAuthComponentState(AuthComponentState.Login);
    // };

    // const onSignUp = async () => {
    //     setAuthComponentState(AuthComponentState.Signup);
    // };

    // const onLogout = async () => {
    //     try {
    //         await logout();
    //     } catch (err) {
    //         toast.error(err);
    //     }
    // };

    return (
        <>
            <GuestInformation />
            {/* {status == AuthenticationStatus.SignedIn ? (
                <div className=" mt-2">
                    <div className="title mb-1 mt-3">
                        <div className="h3">Account</div>
                        <div className="separator-2"></div>
                    </div>
                    <div>
                        Currently logged in as <span className="text-bold">{user?.firstName}</span>
                    </div>
                    <div className="mt-2">
                        Not you? <Link onClick={onLogout}>Log Out</Link>
                    </div>
                </div>
            ) : authComponentState == AuthComponentState.Login ? (
                <Login onSignUp={onSignUp} />
            ) : authComponentState == AuthComponentState.Signup ? (
                <Signup onLogin={onLogin} />
            ) : authComponentState == AuthComponentState.SignupConfirm ? (
                <SignupConfirm />
            ) : authComponentState == AuthComponentState.ForgotPassword ? (
                <ForgotPassword />
            ) : authComponentState == AuthComponentState.ForgotPasswordReset ? (
                <ForgotPasswordReset />
            ) : (
                <div>Invalid State</div>
            )} */}
        </>
    );
};
